<template>

  <v-sheet
    class="d-flex"
    light
    elevation="15"
    width="100%"
    height="150"
  >

    <v-container fill-height>
      <v-layout align-center justify-center row fill-height>
        <v-chip class="ml-1" v-for="word in phrase" :key="word.id" label @click="learn(word.id,word.text,word.trans)">
              {{word.text}}
        </v-chip>
      </v-layout>
    </v-container>

  </v-sheet>

</template>

<script>

import Timer from '../../plugins/timer'

export default {
  name: 'CaptionsViewer',
  data() {
    return {
      captions: [],
      phrase: [],
      timeoutInit: false,
      timeoutFinish: false,
      clicks: 0,
      time: 0,
      initTime: 0,
      isPaused: false,
      initOnFinishPause: false
    }
  },
  beforeMount: function(){
    let that = this
    this.$root.$on('onHelp',function(){
      that.isPaused = true
      if (that.timeoutInit){
        that.timeoutInit.pause()
      } 
      if (that.timeoutFinish){
        that.timeoutFinish.pause()
      } 
    })
    this.$root.$on('offHelp',function(){
      that.isPaused = false
      if (that.timeoutInit){
        that.timeoutInit.resume()
      } 
      if (that.timeoutFinish){
        that.timeoutFinish.resume()
      } 
      if (that.initOnFinishPause){
        that.initCaptions(that.initOnFinishPause.captions,that.initOnFinishPause.initTime)
      }
    })
  },
  methods: {
    initCaptions: function(captions,initTime){

      if (this.isPaused) {
        this.initOnFinishPause = {
          captions , initTime
        }
        return
      }

      this.initOnFinishPause = false

      this.captions = captions;

      if (initTime){
        this.initTime = initTime * 1000
      }
      this.showCaption(0);
    },
    showInitialText: function(captions){
      this.captions = captions
      this.phrase = this.captions[0].text
    },
    learn(id,text,trans){
      if (this.$store.state.idsUnknownWords.indexOf(text) !== -1 || !trans){
        return;
      }
      this.clicks++;
      this.$store.state.unknownWords.pop();
      this.$store.state.idsUnknownWords.pop();
      this.$store.state.mainUnknownWord = id;
      this.$store.state.unknownWords.unshift( { 
        text , 
        idText: id,
        trans , 
        idTrans: id,
        outlineText : false , 
        outlineTrans: false, 
        id,
        uniqueId: 'click'+this.clicks
      } );
      this.$store.state.idsUnknownWords.unshift(text);
    },
    showCaption(index){
      if (!this.captions[index]){
        return;
      }
      let that = this;
      this.timeoutInit = new Timer(function(){
        that.phrase = that.captions[index].text;
      }, ((this.captions[index].init * 1000) - this.initTime) - this.time);

      this.timeoutFinish = new Timer(function(){
        that.time = that.captions[index].finish * 1000;
        index++;
        that.phrase = [];
        that.showCaption(index);
      }, ((this.captions[index].finish * 1000) - this.initTime) - this.time);
    }
  },
  beforeDestroy(){
    this.$root.$off('onHelp')
    this.$root.$off('offHelp')
    if (this.timeoutInit){
      this.timeoutInit.destroy()
    }
    if (this.timeoutFinish){
      this.timeoutFinish.destroy()
    }
  }
}
</script>

<style>

</style>
