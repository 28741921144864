<template>

</template>

<script>

export default {
  name: 'audio-basic',
  data() {
    return {
      audio : false,
      started: false,
      controlVolume: false,
      initEvaluation: 0,
      finishEvaluation: 0,
      secondsNow: 0,
      duration: 0,
      initAudio: 0,
      isPaused: false,
      initOnFinishPause: false
    }
  },
  beforeMount: function(){
    let that = this
    this.$root.$on('onHelp',function(){
      that.isPaused = true
      if (that.audio){
      	that.audio.pause()
      } 
    })
    this.$root.$on('offHelp',function(){
      that.isPaused = false
      if (that.audio){
      	that.audio.play()
      } else if (that.initOnFinishPause){
      	that.init(that.initOnFinishPause.initMoment,that.initOnFinishPause.finishMoment,that.initOnFinishPause.status,that.initOnFinishPause.resource)
      }
    })
  },
  methods: {
    init: function(initMoment,finishMoment,status,resource){

		this.initEvaluation = initMoment
		this.initAudio = status == 1 ? 0 : (this.initEvaluation - 2)
		if (this.initAudio < 0){
			this.initAudio = 0
		}

    	if (this.isPaused){
    		this.initOnFinishPause = {
    			initMoment,finishMoment,status,resource
    		}
    		return this.initAudio
    	}
    	this.initOnFinishPause = false

		this.$store.state.fromStart = true
	    this.$store.state.loading = true

		this.secondsNow = this.initAudio
		this.finishEvaluation = finishMoment
		let finishAudio = this.finishEvaluation + 2
		this.duration = Math.floor(finishAudio - this.initAudio)
	    this.audio = new Audio(this.$store.state.urlApi+resource+'?auth='+this.$store.state.accessToken+'#t='+this.initAudio+','+finishAudio)
	    this.audio.preload = 'auto'
		this.audio.volume = status == 1 ? 1 : 0
	    let that = this
	    this.audio.oncanplaythrough = function() {
	      if (!that.started){
	        that.audio.play()
	      }
	    }

	    this.audio.onplay = function() {
			if (!that.started){
		        that.started = true
		        that.$store.state.loading = false
		        that.$root.$emit('onPlayAudio')
		        let notifyCount = 0
				that.controlVolume = setInterval(function() {
					if (that.isPaused){
						return
					}
					that.secondsNow = that.secondsNow + 0.1
					if (that.secondsNow < that.initEvaluation && that.audio.volume < 0.8){
						that.audio.volume += 0.05
					} else if (that.secondsNow > that.finishEvaluation && that.audio.volume > 0.2){
						that.audio.volume -= 0.05
					}
					if (that.audio.volume !== 1 && that.secondsNow > that.initEvaluation && that.secondsNow < that.finishEvaluation){
						that.audio.volume = 1
					}
					if ((that.secondsNow - that.initAudio) >= notifyCount){
          				that.$root.$emit('onProgressChronometer', { seconds: notifyCount,duration: that.duration } )
          				notifyCount++
        			}
				}, 100);

	      
	      	}
	    }

	    this.audio.onpause = function() {
	    	if (that.controlVolume && !that.isPaused){
	    		clearInterval(that.controlVolume)
	    		if (that.$store.state.step !== false){
	    			that.$root.$emit('onFinishAudio')
	    		}
	    	}
	    }
    	return this.initAudio
    }
  },
  beforeDestroy(){
    this.$root.$off('onHelp')
    this.$root.$off('offHelp')
    if (this.audio){
      this.audio.pause()
      this.audio.currentTime = 0
    }
	if (this.controlVolume){
		clearInterval(this.controlVolume)
	}
  }
}
</script>

<style>

</style>
