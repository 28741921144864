<template>
<div>
<bar ref="bar" close="true" step="Escuchar" :help="instructions"/>

<v-content>
  	<message/>
	<v-container grid-list-md>

	  <v-btn
	    color="primary"
	    block
	    v-show="!$store.state.fromStart"
	    @click="init()"
	  >
	    CONTINUAR LA PRUEBA
	  </v-btn>

	  <v-layout column wrap align-center justify-center>

	    <audio-basic ref="audioBasic"/>
	    <captions-viewer ref="captionsViewer" class="mt-1"/>
	    <dictionary ref="dictionary" :random="false"/>

	  </v-layout>
	</v-container>
</v-content>

</div>
</template>

<script>

import bar from '../components/appComponents/Bar.vue'
import message from '../components/appComponents/Message.vue'
import captionsViewer from '../components/studyUtils/CaptionsViewer.vue'
import dictionary from '../components/studyUtils/Dictionary.vue'
import audioBasic from '../components/studyUtils/AudioBasic.vue'

export default {
  name: 'study',
  components: {
    'captions-viewer':captionsViewer,
    dictionary,
    'audio-basic' : audioBasic,
    message,
    bar
  },
  data() {
    return {
      instructions: {
        title : 'Escuchar en Modo Avanzado',
        text : 'Ahora sólo tienes que escuchar y leer.',
        titleSecondary : 'El modo avanzado',
        textSecondary : 'En el modo avanzado realizarás el ejercicio sobre el poema entero. Es prácticamente imposible superar el modo avanzado en un sólo intento, concéntrate en acertar el máximo de palabras cada vez, todas las palabras acertadas quedarán guardadas para otros intentos que realices después o incluso otro día.',
        key : 'instructionsAdvancedStudy'
      }
    }
  },
  mounted(){

    this.$store.state.step.learnWords = {
      titleText : '2/3 Repasa las palabras',
      redirect : 'play'
    }

    if(this.$store.state.fromStart){
      this.init()
    }
  },
  methods: {
    init: function(){
      this.$store.state.fromStart = true
      this.$store.state.loading = true
      this.$store.state.unknownWords = []
      
      this.$http.get(this.$store.state.urlApi+'step/'+this.$store.state.step.id+'/captions',
        { headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '+this.$store.state.accessToken
      }}).then(response => {

      	this.$refs.bar.setProgress(0,this.$store.state.step.duration)
		    this.$refs.audioBasic.init(0,this.$store.state.step.duration,1,'step/'+this.$store.state.step.id+'/audio')
		    let that = this
        this.$root.$on('onPlayAudio',function(){
          that.$refs.captionsViewer.initCaptions(response.body.calculatedCaptions)
        })
        this.$root.$on('onProgressChronometer',function(data){
          that.$refs.bar.setProgress(data.seconds,that.$store.state.step.duration)
        })
        this.$root.$on('onFinishAudio',function(){
          that.$store.state.currentRoute = '/learn-words'
          that.$router.push(that.$store.state.currentRoute)
        })
        this.$refs.dictionary.initUnknownWords(response.body.calculatedCaptions)
     
      }, function() {
        this.$store.state.loading = false
        this.$store.state.refreshHome = true
        this.$store.state.currentRoute = this.$store.state.errorRedirect
        this.$router.push(this.$store.state.currentRoute)
      });

    }
  },
  beforeDestroy(){
  	this.$root.$off('onPlayAudio')
  	this.$root.$off('onProgressChronometer')
    this.$root.$off('onFinishAudio')
  }
}
</script>

<style>

</style>
