<template>

  <v-container grid-list-md text-xs-center>
    <v-layout row wrap>
      <v-flex v-for="word in $store.state.unknownWords" :key="word.uniqueId" xs12 md6>

        <v-layout align-center justify-center row fill-height>
          <v-flex xs6>
            <v-btn block :disabled="!random && $store.state.mainUnknownWord !== word.id" :outlined="random" :color="getColorLeft(word.outlineText)" @click="text(word.idText)">{{word.text}}</v-btn>
          </v-flex>
          <v-flex xs6>
            <v-btn block :disabled="!random && $store.state.mainUnknownWord !== word.id" :outlined="random" :color="getColorRight()" @click="trans(word.idTrans)">{{word.trans}}</v-btn>
          </v-flex>
        </v-layout>

      </v-flex>
    </v-layout>
  </v-container>

</template>

<script>

export default {
  name: 'Dictionary',
  props: ['random','redirect','order'],
  data() {
    return {
      idSaved: false,
      solvedWord : {}
    }
  },
  beforeMount(){
    if (!this.order){
      this.modify(false)
    }
  },
  methods: {
    initUnknownWords(calculatedCaptions) {
      this.$store.state.mainUnknownWord = false;

      // all words  in one array
      const allWords = [];
      for (let i in calculatedCaptions){
        for (let k in calculatedCaptions[i].text){
          allWords.push(calculatedCaptions[i].text[k]);
        }
      }

      // get four words random
      this.$store.state.idsUnknownWords = [];
      endloop:
      while (this.$store.state.unknownWords.length < 4){
        let i = this.randomIntFromInterval(0,allWords.length - 1);
        let attempts = 0;
        while (this.$store.state.idsUnknownWords.indexOf(allWords[i].text) !== -1){
          if (attempts > 7){
            break endloop
          }
          i = this.randomIntFromInterval(0,allWords.length - 1);
          attempts++;
        }
        this.$store.state.unknownWords.unshift( { 
          text : allWords[i].text, 
          idText: allWords[i].id,
          trans : allWords[i].trans, 
          idTrans: allWords[i].id,
          outlineText : false , 
          outlineTrans: false, 
          id: allWords[i].id,
          uniqueId: allWords[i].id
        } );
        this.$store.state.idsUnknownWords.push(allWords[i].text);
        attempts++;
      }
    },
    randomIntFromInterval(min,max) // min and max included
    {
        return Math.floor(Math.random()*(max-min+1)+min);
    },
    getColorLeft: function(outlined){
      if (!this.random || outlined){
        return 'warning';
      } 
      return 'white';
    },
    getColorRight: function(){
      if (!this.random){
        return 'warning';
      }
      return 'white';
    },
    text: function (id){
      if (this.idSaved === false && this.random){
        this.idSaved = id;
      } else if(this.idSaved === id) {
        this.idSaved = false;
      } else {
        return;
      }

      let i = this.getKeyById(id,'idText',this.$store.state.unknownWords);
      this.$store.state.unknownWords[i].outlineText = !this.$store.state.unknownWords[i].outlineText;
    },
    trans: function (id){
      if (this.idSaved === false){
        return;
      } 
      let i = this.getKeyById(this.idSaved,'idText',this.$store.state.unknownWords);
      if(this.idSaved !== id){
        this.idSaved = false;
        this.$store.state.unknownWords[i].outlineText = !this.$store.state.unknownWords[i].outlineText;
        new Audio('fail.mp3').play()
        return;
      }
      //new Audio('win.wav').play()
      this.idSaved = false;
      this.modify(id);
    },
    getKeyById: function(id,idName,collection){
      for (let i in collection){
        if (collection[i][idName] === id){
          return i;
        }
      }
    },
    modify: function (deleteId) {
      let trans = [];
      let texts = [];
      for (let i in this.$store.state.unknownWords){
        if (!deleteId  || this.$store.state.unknownWords[i].idTrans !== deleteId) {
          trans.push( { 
            id : this.$store.state.unknownWords[i].idTrans ,
            trans: this.$store.state.unknownWords[i].trans
          } );
        } else {
          this.solvedWord.trans = this.$store.state.unknownWords[i].trans
        }
        if (!deleteId  || this.$store.state.unknownWords[i].idText !== deleteId) {
          texts.push( { 
            id : this.$store.state.unknownWords[i].idText ,
            text: this.$store.state.unknownWords[i].text
          } );
        } else {
          this.solvedWord.text = this.$store.state.unknownWords[i].text
        }
      }

      if (this.random){
        texts = this.shuffle(texts);
        trans = this.shuffle(trans);
      }
      
      let newUnknownWords = [];
      for (let i in texts){
        let k = (this.random) ? i : this.getKeyById(texts[i].id,'id',trans);
        if (!k){
          continue;
        }
        let transAffected = trans[k];
        newUnknownWords.push({ 
          text : texts[i].text, 
          idText: texts[i].id,
          trans: transAffected.trans, 
          idTrans: transAffected.id,
          outlineText : false , 
          outlineTrans: false, 
          id: i
        });
      
      }
      this.$store.state.unknownWords = newUnknownWords;
      this.solvedWord.id = deleteId
      this.$root.$emit('solvedWord',this.solvedWord);
      if (this.random === true && this.$store.state.unknownWords.length === 0 && this.redirect){
        this.$store.state.currentRoute = '/'+this.redirect
        this.$router.push(this.redirect)
      }
    },
    shuffle : function(input) {
        for (let i = input.length-1; i >=0; i--) {
            let randomIndex = Math.floor(Math.random()*(i+1)); 
            let itemAtIndex = input[randomIndex]; 
            input[randomIndex] = input[i]; 
            input[i] = itemAtIndex;
        }
        return input;
    }
  }
}
</script>

<style>

</style>
